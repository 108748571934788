<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t($route.name) }}</b-card-title>
      <b-button
        class=""
        variant="primary"
        @click="openModal(null, 'Create')"
        v-if="resolvePermission('pages.create')"
      >
        <feather-icon icon="PlusIcon" size="15"> </feather-icon>
        {{ $t("add new") }}
      </b-button>
    </b-card-header>
    <b-row class="mx-1">
      <b-col class="col-9 col-md-11">
        <b-form-group :label="$t('Search')" label-for="basicInput">
          <b-form-input id="basicInput" v-model="searchText" />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          label="Basic Input"
          label-for="selectInput"
        >
          <b-form-select
            id='selectInput'
            v-model="selected"
            :options="options"
          />
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="options"
          />
        </b-form-group>
      </b-col> -->
      <b-col class="col-3 col-md-1 align-self-center">
        <b-button
          variant="primary"
          class="btn my-auto"
          :disabled="getLoading"
          @click="filterItems"
        >
          <feather-icon icon="SearchIcon" size="16"></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table responsive :items="pages" :fields="fields" class="mb-0">
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(path)="data">
        <a
          :href="baseURL + '/' + data.value"
          target="_blank"
          class="text-truncate text-nowrap"
        >
          {{ baseURL + "/" + data.value }}
        </a>
      </template>
      <template #cell(status)="data">
        <div>
          <b-avatar
            size="32"
            :variant="`light-${data.value ? 'success' : 'danger'}`"
          >
            <feather-icon :icon="data.value ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </div>
      </template>
      <template #cell(actions)="row">
        <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
        <b-button
          size="sm"
          @click="editItem(row)"
          variant="flat-dark"
          class="btn-icon"
          v-if="resolvePermission('pages.edit')"
        >
          <feather-icon icon="Edit2Icon" size="15" />
        </b-button>
        <b-button
          size="sm"
          @click="deleteItem(row)"
          variant="flat-danger"
          class="btn-icon"
          v-if="resolvePermission('pages.delete')"
        >
          <feather-icon icon="Trash2Icon" size="15" />
        </b-button>
      </template>
    </b-table>
    <p class="text-center py-4" v-if="pages.length == 0">
      {{ $t("noPagesFound") }}
    </p>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @input="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardFooter,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { mapActions, mapGetters } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardFooter,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BPagination,
    BAvatar,
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("ID"),
        },
        ,
        {
          key: "titles",
          label: this.$t("name"),
          formatter: (value, ket, item) => {
            const title = item.titles
              ? item.titles[this.$i18n.locale]
              : item.title;
            if (
              !title ||
              title === "null" ||
              title === "false" ||
              title === "undefined"
            ) {
              return this.$t(this.$i18n.locale) +" "+ this.$t("Not activated");
            } else {
              return title;
            }
          },
        },
        {
          key: "ord",
          label: this.$t("Order"),
        },
        {
          key: "path",
          label: this.$t("url"),
        },
        {
          key: "status",
          label: this.$t("showinheader"),
        },
        // {
        //   key: 'status',
        //   label: this.$t('status')
        // },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: "actions",
          label: this.$t("actions"),
        },
      ],
      baseURL: "",
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: "",
    };
  },
  directives: {
    Ripple,
  },
  props: ["openModal", "closeModal", "confirmDelete"],
  created() {
    this.filterItems();
    if (this.theSettings.social_media_links) {
      for (let i = 0; i <= this.theSettings.social_media_links.length; i++) {
        if (
          this.theSettings.social_media_links[i] &&
          this.theSettings.social_media_links[i].name == "baseurl"
        ) {
          this.baseURL = this.theSettings.social_media_links[i].link;
          setTimeout(() => {
            this.formgl = false;
          }, 100);
        }
      }
    }
  },

  methods: {
    ...mapActions(["getPages", "deletePage", "getPage", "getSettings"]),
    filterItems() {
      this.getPages({
        page: this.currentPage,
        keywords: this.searchText,
      })
        .then((res) => {
          this.rows = res.data.results.total;
        })
        .catch((err) => {});
    },
    showItem(row) {
      this.openModal(row, "Show");
    },

    editItem(row) {
      this.openModal(row, "Edit");
    },

    deleteItem(row) {
      this.confirmDelete(this.deletePage, row);
    },
  },

  computed: {
    ...mapGetters(["pages", "settings"]),
  },
};
</script>
